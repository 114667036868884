@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  height: 48px;
  width: 48px;

  justify-content: center;
  align-items: center;

  background: transparent;
  border: 2px solid var(--color-gray);
  border-radius: 100%;

  user-select: none;
  @include mixins.transition(ease 0.3s all);

  &_active {
    background: var(--color-light);
    border: 2px solid var(--color-primary);

    .icon {
      color: var(--color-primary);
    }
  }

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }
}

.icon {
  height: 24px;
  width: 24px;

  color: var(--color-light);
}
