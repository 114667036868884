.fallback {
  display: flex;

  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin-bottom: 16px;

  font-size: 28px;
  line-height: 120%;
  font-weight: 300;
  color: (--color-gray);
}

.error {
  margin-bottom: 16px;

  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-error);
}
