.container {
  display: flex;

  padding: 15px;

  flex-direction: column;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 20px;

  user-select: none;
}

.title {
  margin-bottom: 10px;

  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: var(--color-dark);
}

.list {
  display: flex;

  flex-wrap: wrap;
  gap: 10px;
}

.placeholder {
  margin: 100px auto 130px;

  max-width: 339px;
  width: 100%;

  & img {
    height: 100%;
    width: 100%;
  }
}
