.container {
  display: flex;

  height: 100%;
  width: 100%;
  flex-grow: 1;

  padding-top: 80px;
  padding-bottom: 40px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;

  max-width: 332px;
  width: 100%;
  flex-grow: 1;

  padding-top: 48px;

  flex-direction: column;
  align-items: center;
}

.success {
  display: flex;

  margin-bottom: auto;
  width: 100%;

  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 32px;

    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: var(--color-dark);
  }

  &__subtitle {
    margin-bottom: 16px;

    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray);
  }
}

.form {
  display: flex;

  margin-bottom: auto;

  width: 100%;

  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 32px;

    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: var(--color-dark);
  }

  &__subtitle,
  &__email {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray);
  }

  &__email {
    margin-bottom: 12px;
  }

  &__button {
    margin-bottom: 32px;
  }

  &__resend {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--color-gray);
  }
}
