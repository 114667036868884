.logo {
  display: flex;

  max-width: 358px;
  width: 100%;

  align-items: center;
}

.link {
  height: 44px;
  max-width: 128px;
  width: 100%;

  & img {
    height: 100%;
    width: 100%;
  }
}

.select-team {
  margin-right: 24px;
}

.search-boards {
  margin-right: auto;
}

.button-premium {
  margin-right: 24px;
}
