@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  position: relative;

  width: 100%;

  flex-direction: column;

  user-select: none;

  &:disabled {
    opacity: 0.3;
    background-color: var(--color-bg-light);
  }

  // &_small {
  //   max-width: 328px;
  // }
  &_big {
    max-width: none;
    flex: 1;
  }
}

.label {
  position: absolute;

  top: 8px;
  left: 20px;

  font-size: 18px;
  line-height: 40px;

  color: var(--color-gray);

  @include mixins.transition(ease-out 0.1s all);
  user-select: none;

  &_compressed {
    top: 4px;

    font-size: 13px;
    line-height: 18px;
  }
}

.input {
  display: block;

  height: 56px;
  width: 100%;

  padding: 8px 20px;

  background: var(--color-light);
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  outline: none;

  font-size: 18px;
  line-height: 40px;

  @include mixins.transition(ease 0.2s all);

  &::placeholder {
    color: var(--color-gray);
  }

  &:focus,
  &:focus-visible {
    border-color: var(--color-primary);
  }

  &_withLabel {
    padding: 13px 20px 3px;
  }

  // &_small {
  //   max-width: 404px;
  // }
  &_big {
    max-width: none;
  }

  &_password {
    padding-right: 64px;
  }

  &_error {
    margin-bottom: 3px;

    background: var(--color-light);
    border-color: var(--color-error);
  }
}

.eyeIcon {
  position: absolute;

  top: 16px;
  right: 16px;

  height: 24px;
  width: 24px;

  background: transparent;
}

.errorMessage {
  display: block;

  margin-left: 20px;

  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: var(--color-error);
}
