.container {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.icon-wrapper {
  display: flex;

  margin-bottom: 16px;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  background: var(--color-green);
  border-radius: 20px;

  color: var(--color-light);
}

.type {
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--color-gray);
}
