.container {
  display: flex;

  height: 198px;
  min-width: 220px;
  max-width: 300px;
  flex: 1 0 0;

  padding: 15px 15px 0px 15px;

  flex-direction: column;

  background: var(--color-light);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;
  border-bottom: 10px solid var(--color-green);
}

.header {
  display: flex;

  margin-bottom: 16px;
  width: 100%;

  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.project-name {
  flex: 1 1 auto;

  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-align: start;
  color: var(--color-gray);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.board-type {
  display: flex;

  flex: 0 0 auto;

  align-items: center;
  gap: 8px;

  &__icon {
    height: 16px;
    width: 16px;
  }

  &__label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.12px;
    text-align: start;
    color: inherit;
  }
}

.board-name {
  margin-bottom: 2px;
  max-width: 100%;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.14px;
  text-align: start;
  color: var(--color-dark-gray);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.board-prompt {
  display: -webkit-box;

  margin-bottom: 16px;
  max-width: 100%;

  font-size: 18px;
  line-height: 24px;
  text-align: start;
  color: var(--color-dark);

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // number of lines to show

  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.board-users {
  margin-bottom: 16px;
}

.board-last-change {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-align: start;
  color: var(--color-light-gray);
}
