.container {
  display: flex;

  padding: 15px;

  flex-direction: column;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 20px;
}

.title {
  margin-bottom: 10px;

  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: var(--color-dark);
}

.slider {
  width: 100%;
}

.slide {
  max-width: 220px;
}
