@use 'styles/common/mixins' as mixins;

.label {
  position: relative;
}

.input {
  display: flex;

  height: 40px;
  width: 300px;

  padding: 10px 67px 10px 20px;

  background: var(--color-light-light-gray);
  border: 1px solid var(--color-light-light-gray);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--color-gray);

  @include mixins.transition(ease-out 0.3s all);

  &::placeholder {
    color: var(--color-gray);
  }

  &:hover:enabled {
    border-color: var(--color-light-gray);
  }

  &:focus:enabled,
  &:focus-visible:enabled {
    background: var(--color-light);
    border-color: var(--color-light-gray);
  }
}

.button {
  display: flex;
  position: absolute;

  top: 12px;
  right: 41px;

  height: 16px;
  width: 16px;

  padding: 2px;

  justify-content: center;
  align-items: center;

  background: var(--color-light-light-gray);
  border-radius: 50%;

  & svg {
    height: 100%;
    width: 100%;

    color: var(--color-gray);
  }
}

.icon,
.spinner {
  position: absolute;

  top: 12px;
  right: 15px;

  height: 16px;
  width: 16px;

  color: var(--color-gray);

  @include mixins.transition(ease-out 0.2s all);
  cursor: text;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
}
