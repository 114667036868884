.container {
  display: flex;
  position: relative;

  width: 100%;
}

.reactflow {
  width: 100%;
}

.knowledge {
  position: absolute;

  top: 55px;
  right: 10px;
}
