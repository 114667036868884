@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  width: 740px;
  
  flex-direction: column;
  align-items: center;
}

.containerForm {
  width: 680px;
}

.form {
  display: flex;

  flex-direction: column;
}

.title {
  margin-bottom: 24px;

  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.21px;
  color: var(--color-dark);
}

.title-subtitle {
  margin: 0 auto;

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
}

.subtitle {
  margin-bottom: 8px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
  text-align: center;
  color: var(--Dark, #2D3C59);
}

.subtitle-grey {
  margin-bottom: 8px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-gray);
}

.buttons {
  display: flex;
  justify-content: space-between;

  gap: 12px;
}
