@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  height: 100%;
  width: 300px;

  padding: 16px;

  gap: 10px;

  background: var(--color-light-green);
  border: 2px solid var(--color-light-green);
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);

  color: var(--color-light);

  overflow: hidden;
  @include mixins.transition(ease 0.3s all);

  &:hover:enabled {
    border-color: var(--color-gray) !important;
  }

  &_active,
  &:active {
    border-color: var(--color-blue) !important;
  }

  &_double {
    background: var(--color-light) !important;
    color: var(--color-dark);
  }

  &:disabled {
    background: var(--color-light-light-gray) !important;
    border-color: var(--color-light-gray) !important;
    color: var(--color-dark);
  }
}

.icon {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.documents {
  display: flex;
  position: relative;

  height: 24px;
  width: 24px;

  justify-content: left;
  align-items: flex-end;

  &__icon {
    height: 18px;
    width: 18px;
  }

  &__badge {
    position: absolute;

    top: 0;
    right: 0;

    height: 13px;
    min-width: 13px;

    padding: 0 3px;

    background: var(--color-primary);
    border-radius: 6.5px;

    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: var(--color-light);
  }
}

.form {
  display: flex;
  flex: 1;

  height: 100%;
  width: 100%;
}

.input,
.text {
  height: 24px;
  width: 100%;

  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: start;

  background: transparent;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.handle {
  height: 8px;
  width: 8px;

  background: var(--color-blue);
  border-radius: 2px;
  border: none;

  &_disabled {
    opacity: 0 !important;
  }
}

.buttonAddEpic {
  height: 24px;
  width: 24px;

  padding: 4px;

  background: var(--color-light-light-gray);
  border-radius: 50%;

  color: var(--color-primary);

  &:disabled {
    display: none;
  }

  & svg {
    height: 100%;
    width: 100%;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

.buttonGenerate {
  height: 32px;
  width: 32px;

  background: var(--color-primary);
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  color: var(--color-light);
}
