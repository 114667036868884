@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  position: relative;

  width: 100%;

  flex-direction: column;

  cursor: pointer;
  user-select: none;

  &:hover {
    .icon {
      color: var(--color-dark);
    }
  }
}

.header {
  display: flex;

  height: 56px;
  width: 100%;

  padding: 16px 20px;

  align-items: center;
  gap: 12px;

  background: var(--color-light);
  border: 1px solid var(--color-gray);
  border-radius: 8px;

  @include mixins.transition(ease 0.2s all);

  &:focus {
    border-color: var(--color-primary);
  }

  &_placeholder {
    color: var(--color-gray);
  }

  &_error {
    margin-bottom: 3px;

    background: var(--color-light);
    border-color: var(--color-error);
  }

  & span {
    flex: 1;

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.01em;

    text-align: start;
  }
}

.icon {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;

  color: var(--color-gray);

  @include mixins.transition(ease 0.2s all);

  &_active {
    transform: rotate(180deg);
  }
}

.menu {
  display: none;
  position: relative;
  z-index: 1;

  top: 4px;

  max-height: 280px;
  width: 100%;

  padding: 8px 0;

  flex-direction: column;

  background-color: var(--color-light);
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;

  &_active {
    display: flex;
  }
}

.options {
  display: flex;

  max-height: 200px;
  width: 100%;

  flex-direction: column;

  border-bottom: 1px solid var(--color-light-light-gray);

  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }
}

.option {
  display: flex;

  height: 40px;
  width: 100%;

  padding: 5px 15px 5px 20px;

  justify-content: space-between;
  align-items: center;
  gap: 10px;

  background: none;

  &:hover,
  &:focus,
  &:focus-visible,
  &_active {
    background: var(--color-bg-purple);
  }

  &:disabled {
    opacity: 0.5;
  }

  & span {
    flex: 1;

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: var(--color-dark);
    text-align: start;
  }
}

.container-add-project {
  display: flex;

  height: 64px;
  padding-top: 16px;

  justify-content: center;
}

.button-add-project {
  color: var(--color-gray);
}

.errorMessage {
  display: block;

  margin-left: 20px;

  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: var(--color-error);
}
