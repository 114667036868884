@use 'styles/common/mixins' as mixins;

.container {
  width: 480px;
}

.form {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.subtitle {
  margin-bottom: 28px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-gray);
}

.buttons {
  display: flex;

  width: 100%;

  align-items: center;
  gap: 12px;
}

.button-delete {
  background: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--color-blue);

  user-select: none;
  @include mixins.transition(ease 0.3s all);

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  &:hover:enabled {
    color: var(--color-primary);
  }
}
