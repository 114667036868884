@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  gap: 4px;
  margin-right: 8px;
}

.header {
  display: flex;

  padding: 17px 15px;

  background: var(--color-light);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border: solid 1px;
  border-radius: 10px;

  align-items: center;
  gap: 10px;

  user-select: none;

  height: 20px;

  border-color: rgba(212, 222, 242, 1);
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(45, 60, 89, 1);
}

.icon {
  width: 20px;
  height: 20px;
}

.users {
  display: flex;
  gap: 4px;
  margin-right: 15px;

  user-select: none;
}
