.container {
  display: flex;

  flex: 1 1 auto;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 30px;

  align-items: center;
}

.title {
  margin-right: auto;

  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.21px;
  color: var(--color-dark);
}

.button-edit {
  height: 40px;
  color: var(--color-gray);
}

.mind-charts {
  margin-bottom: 30px;
}
