@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  padding: 12px;

  gap: 12px;

  background: var(--color-dark-gray);

  user-select: none;
}

.label {
  display: flex;
  position: relative;

  padding: 6px 48px 10px 10px;

  background: var(--color-dark);
  border-radius: 8px;
  border: 2px solid var(--color-blue);

  height: 100%;
  width: 100%;
  flex: 1;
}

.textarea {
  min-height: 48px;
  max-height: 192px;

  height: 100%;
  width: 100%;

  background: var(--color-dark);
  outline: none;

  font-size: 16px;
  line-height: 24px;
  color: var(--color-light-light-gray);

  word-wrap: break-word;

  resize: none;
  overflow: visible;
}

.icon-edit {
  position: absolute;

  top: 8;
  right: 16px;

  height: 24px;
  width: 24px;

  color: #6b7a99;
}

.counter {
  position: absolute;

  right: 11px;
  bottom: 4px;

  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.12px;
  color: var(--color-gray);
}

.button-generate {
  height: auto;
  width: 124px;
}
