.container {
  display: flex;

  height: 100%;
  width: 100%;
  flex-grow: 1;

  padding-top: 80px;
  padding-bottom: 40px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;

  max-width: 328px;
  width: 100%;
  flex-grow: 1;

  padding-top: 48px;

  flex-direction: column;
  align-items: center;
}

.content__title {
  margin-bottom: 32px;
}

.form {
  display: flex;

  margin-bottom: 32px;

  width: 100%;

  flex-direction: column;
  align-items: center;
}

.form__fields {
  display: flex;

  margin-bottom: 12px;

  width: 100%;

  flex-direction: column;
}

.email,
.password,
.confirm {
  margin-bottom: 12px;
}
.name {
  margin-bottom: 32px;
}

.divider {
  margin-bottom: 12px;
}

.buttonGoogle {
  margin-bottom: 32px;
}

.login {
  margin-bottom: auto;

  padding-bottom: 40px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-bg-dark);

  text-align: center;
  letter-spacing: 0.01em;
}
