@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  padding: 12px 8px;

  flex-direction: column;
  align-items: center;
  gap: 8px;

  background: var(--color-dark);
  border-radius: 4px;
}

.button {
  display: flex;

  height: 40px;
  width: 48px;

  justify-content: center;
  align-items: center;

  background: var(--color-dark);
  border-radius: 4px;

  color: var(--color-light-gray);

  @include mixins.transition(ease 0.2s all);

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:enabled {
    background: var(--color-gray);
    color: var(--color-light);
  }

  &_active,
  &_active:hover:enabled {
    background: var(--color-primary);
    color: var(--color-light);
  }
}

.tooltip {
  display: flex;

  padding: 0;

  flex-direction: column;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  user-select: none;
}

.tooltip__item {
  display: flex;

  width: 100%;
  padding: 8px 12px;

  gap: 10px;

  background: none;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-light-gray);

  @include mixins.transition(ease 0.2s all);

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:enabled {
    background: var(--color-gray);
    color: var(--color-light);

    & .key {
      background: var(--color-dark);
      color: var(--color-light);
    }
  }

  &_active,
  &_active:hover:enabled {
    background: var(--color-primary);
    color: var(--color-light);
  }
}

.key {
  height: 24px;
  width: 20px;

  background: var(--color-bg-dark);
  border-radius: 2px;

  color: var(--color-light-light-gray);

  @include mixins.transition(ease 0.2s all);
}
