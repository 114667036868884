@use 'styles/common/mixins' as mixins;

.container {
  position: relative;

  user-select: none;
}

.menu {
  display: none;
  position: absolute;
  z-index: 5;

  top: 50px;

  width: 100%;

  padding: 8px 0;

  flex-direction: column;

  background-color: var(--color-light);
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.07);

  &_active {
    display: flex;
  }
}

.options {
  display: flex;

  max-height: 224px;
  width: 100%;

  flex-direction: column;
  gap: 8px;

  border-bottom: 1px solid var(--color-light-light-gray);

  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }
}

.loading,
.no-options {
  height: 40px;
  width: 100%;

  padding: 5px 15px 5px 20px;

  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: start;

  opacity: 0.5;
}
