@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  height: 100px;
  width: 300px;

  padding: 16px;

  gap: 10px;

  background: var(--color-light-blue);
  border: 2px solid var(--color-light-blue);
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);

  color: var(--color-light);

  overflow: hidden;
  @include mixins.transition(ease 0.3s all);

  &:hover:enabled {
    border-color: var(--color-gray);
  }

  &_active,
  &:active {
    border-color: var(--color-blue);
  }

  &_double {
    background: var(--color-light);
    color: var(--color-dark);
  }

  &:disabled {
    background: var(--color-light-light-gray);
    border-color: var(--color-light-gray);
    color: var(--color-dark);
  }
}

.knowledge {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.documents {
  display: flex;
  position: relative;

  height: 24px;
  width: 24px;

  justify-content: left;
  align-items: flex-end;

  &__icon {
    height: 18px;
    width: 18px;
  }

  &__badge {
    position: absolute;

    top: 0;
    right: 0;

    height: 13px;
    min-width: 13px;

    padding: 0 3px;

    background: var(--color-primary);
    border-radius: 6.5px;

    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: var(--color-light);
  }
}

.form {
  display: flex;

  height: 100%;
  width: 100%;

  flex: 1;
}

.textarea,
.text {
  height: 100%;
  width: 100%;

  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  text-align: start;

  background: transparent;
}

.textarea {
  resize: none;
  overflow-y: auto;
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  overflow: hidden;
}

.handle {
  height: 8px;
  width: 8px;

  background: var(--color-blue);
  border-radius: 2px;
  border: none;

  &_disabled {
    opacity: 0 !important;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

.buttonGenerate {
  height: 32px;
  width: 32px;

  background: var(--color-primary);
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  color: var(--color-light);
}
