@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  flex-grow: 1;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 30px;

  align-items: center;
  gap: 16px;
}

.button-back {
  height: 48px;
  width: 24px;

  flex-shrink: 0;

  background: none;
}

.icon-back {
  height: 24px;
  width: 24px;

  color: var(--color-dark);
  transform: rotate(180deg);
}

.icon-document {
  height: 48px;
  width: 48px;

  flex-shrink: 0;

  & svg {
    height: 100%;
    width: 100%;
  }
}

.name {
  flex-grow: 1;

  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.21px;
  text-align: start;
  color: var(--color-dark);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.linked {
  display: flex;

  flex-grow: 1;

  padding: 15px;

  flex-direction: column;
  gap: 15px;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 20px;

  &__title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.2px;
    text-align: start;
    color: var(--color-dark);
  }

  &__list {
    display: flex;

    flex-wrap: wrap;
    gap: 10px;
  }
}

.placeholder {
  margin: 100px auto 130px;

  max-width: 339px;
  width: 100%;

  & img {
    height: 100%;
    width: 100%;
  }
}
