@use 'styles/common/mixins' as mixins;

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: var(--z-index-hidden);
  opacity: 0;
  pointer-events: none;

  @include mixins.transition(ease-in-out 0.3s all);
}

.opened {
  z-index: var(--z-index-modal);
  opacity: 1;
  pointer-events: auto;

  .content {
    transform: scale(1);
  }
}

.closing {
  .content {
    transform: scale(0.6);
  }
}

.backdrop {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--color-overlay);

  cursor: pointer;
}

.content {
  margin: 0 30px;

  max-height: 90%;

  padding: 60px 120px;

  display: flex;

  background: var(--color-light);
  border-radius: 10px;

  overflow-y: auto;
  overflow-x: hidden;

  transform: scale(0.6);

  cursor: auto;

  @include mixins.transition(ease-in-out 0.3s transform);
}

.button-delete {
  position: absolute;

  height: 24px;
  width: 24px;

  top: 20px;
  right: 20px;

  background: none;

  color: var(--color-gray);
}
