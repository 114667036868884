.container {
  display: flex;
  width: 800px;
  
  flex-direction: column;
  align-items: center;
}

.containerForm {
  width: 560px;
}

.form {
  display: flex;

  margin-bottom: 32px;

  flex-direction: column;
}

.title {
  margin-bottom: 12px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}



.type {
  display: flex;

  margin-bottom: 24px;
  padding: 0 15px;

  align-self: center;
  gap: 10px;

  background: var(--color-light);
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}

.icon {
  height: 24px;
  width: 24px;

  color: var(--color-dark);
}

.label {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-dark);
}

.text {
  margin-bottom: 24px;

  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-gray);
  text-align: center;
}

.invite {
  margin-bottom: 24px;

  &__form {
    display: flex;

    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray);

    & .bold {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: var(--color-dark);
    }
  }

  &__field {
    display: flex;

    width: 100%;

    .input-name {
      border-radius: 8px 0px 0px 8px;
      border-right: none;
    }

    .select-role {
      margin-right: 8px;
      max-width: 180px;

      &__header {
        padding: 13px 20px;
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
}

.user-list {
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #EDEFF2;
  
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  margin-bottom: 32px;
}

.button-all {
  max-width: 149px;

  align-self: center;
}

.one-user {
  display: flex;
  flex-direction: row;

  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  gap: 10px;
  box-shadow: 0px 10px 30px 0px #00000017;
  width: 100%;
}

.avatar {
  display: flex;

  margin-right: 4px;

  min-height: 32px;
  height: 32px;
  min-width: 32px;
  width: 32px;

  justify-content: center;
  align-items: center;

  background: var(--color-bg-light);
  border-radius: 20px;

  color: var(--color-gray);

  & svg {
    height: 20px;
    width: 20px;
  }
}

.button-copy {
  max-width: 300px;

  align-self: center;
}

.username {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2D3C59;
  
  margin-left: 8px;
  padding-top: 5px;
}

.role {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: right;
  color: #6B7A99;

  width: 46px;
  padding-top: 2px;
  padding-right: 8px;
}

.buttonSubmit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 16px;

  margin-bottom: 60px;
}
