@use 'styles/common/mixins' as mixins;

.contextbar {
  display: flex;

  padding: 8px;

  align-items: center;
  gap: 8px;

  background: var(--color-dark);
  border-radius: 4px;

  &__button {
    display: flex;

    padding: 8px;

    justify-content: center;
    align-items: center;

    background: var(--color-dark);
    border-radius: 4px;

    color: var(--color-light-gray);

    @include mixins.transition(ease 0.2s all);

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }

    &:hover:enabled {
      background: var(--color-gray);
      color: var(--color-light);
    }

    &_active,
    &_active:hover:enabled {
      background: var(--color-primary);
      color: var(--color-light);
    }

    &_delete {
      align-items: center;
      gap: 10px;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
