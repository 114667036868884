.header {
  position: fixed;
  z-index: 5;

  top: 0;
  left: 0;
  right: 0;

  background-color: var(--color-light);
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);

  user-select: none;
}

.container {
  display: flex;

  height: 64px;
  padding: 10px 16px;

  align-items: center;

  @media screen and (max-width: 1023px) {
    justify-content: space-between;
  }
}
