@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  flex: 1 1 auto;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 20px;

  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.title {
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.21px;
  color: var(--color-dark);
}
