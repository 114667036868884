.section {
  width: 100%;

  padding: 70px 0 86px;

  background-color: var(--color-bg-light);
  background-image: url('~assets/images/png/landing/testimonials-bg.png');
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;

  user-select: none;

  @media screen and (max-width: 1024px) {
    padding: 70px 0 90px;
  }

  @media screen and (max-width: 800px) {
    padding: 36px 0 90px;
  }
}

.container {
  display: flex;

  height: 100%;

  max-width: 1220px;
  padding: 0 20px;

  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}

.header {
  margin-bottom: 47px;

  @media screen and (max-width: 800px) {
    margin-bottom: 42px;
  }
}

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.swiper {
  padding-bottom: 12px;
}

.slide {
  max-width: 340px;
}

.card {
  display: flex;

  width: 100%;
  padding: 45px;

  flex-direction: column;

  background: var(--color-light);
  border-radius: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.09);

  @media screen and (max-width: 1024px) {
    width: 300px;
    padding: 30px;
  }
}

.card__header {
  display: flex;

  margin-bottom: 16px;
}

.avatar {
  margin-right: 16px;

  max-width: 56px;
  width: 100%;

  & img {
    width: 100%;
  }
}

.info {
  display: flex;

  // max-width: 178px;

  flex-direction: column;
}

.name {
  margin-bottom: 4px;

  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: var(--color-dark);
}

.position {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--color-dark);
}

.comment {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  list-style-position: outside;
  padding: 0;
  color: var(--color-gray);
}
