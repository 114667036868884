@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  height: 100%;
  width: 100%;

  padding: 8px;

  background: none;
  border: 2px solid transparent;

  overflow: hidden;
  @include mixins.transition(ease 0.2s all);

  &:hover:enabled {
    border-bottom: 2px solid var(--color-blue);
  }

  &_double {
    border: 2px solid var(--color-primary);
  }

  &_active,
  &:active {
    border: 2px solid var(--color-blue) !important;
  }
}

.form {
  display: flex;
  flex: 1;

  height: 100%;
  width: 100%;
}

.textarea,
.text {
  height: 100%;
  width: 100%;

  color: var(--color-dark);
  text-align: start;

  background: transparent;

  resize: none;
  overflow: hidden;
}

.buttonGenerate {
  height: 32px;
  width: 32px;

  background: var(--color-primary);
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  color: var(--color-light);
}

.contextbar {
  display: flex;

  padding: 8px;

  align-items: center;
  gap: 8px;

  background: var(--color-dark);
  border-radius: 4px;
}

.contextbar__button {
  display: flex;

  padding: 8px;

  justify-content: center;
  align-items: center;

  background: var(--color-dark);
  border-radius: 4px;

  color: var(--color-light-gray);

  @include mixins.transition(ease 0.2s all);

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:enabled {
    background: var(--color-gray);
    color: var(--color-light);
  }

  &_active,
  &_active:hover:enabled {
    background: var(--color-primary);
    color: var(--color-light);
  }

  &_size,
  &_delete {
    align-items: center;
    gap: 10px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.tooltip {
  z-index: 5;

  padding: 8px;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  user-select: none;
}

.tooltip__list {
  display: flex;

  flex-direction: column;
}

.tooltip__item {
  display: flex;

  width: 100%;
  padding: 16px 24px 16px 16px;

  gap: 16px;

  background: none;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-light-gray);

  @include mixins.transition(ease 0.2s all);

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:enabled {
    background: var(--color-gray);
    color: var(--color-light);

    & .key {
      background: var(--color-dark);
      color: var(--color-light);
    }
  }

  &_active,
  &_active:hover:enabled {
    background: var(--color-primary);
    color: var(--color-light);
  }
}
