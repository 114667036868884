@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  height: 100%;
  width: 100%;

  padding: 6px 8px 6px 16px;

  align-items: center;
  gap: 10px;

  background: var(--color-light);
  border: 2px solid var(--color-light);

  color: var(--color-dark);

  overflow: hidden;
  @include mixins.transition(ease 0.3s all);

  &:hover:enabled {
    color: var(--color-blue);
  }

  &_active,
  &:active {
    border-color: var(--color-blue);
  }

  &:disabled {
    background: var(--color-light-light-gray);
    border-color: var(--color-light-gray);
    color: var(--color-dark);
  }
}

.form {
  display: flex;
  flex: 1;

  height: 100%;
  width: 100%;
}

.input,
.text {
  height: 20px;
  width: 100%;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: start;

  background: transparent;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flag {
  display: flex;

  height: 20px;
  width: 20px;

  justify-content: center;
  align-items: center;

  color: var(--color-yellow);
}

.line {
  position: absolute;
  z-index: 1;
  content: '';

  top: 0;
  right: 0;

  height: 3000px;
  width: 1px;

  border-left: 2px solid var(--color-yellow);
}

.buttonAddMilestone {
  height: 24px;
  width: 24px;

  padding: 4px;

  background: var(--color-light-gray);
  border-radius: 50%;

  color: var(--color-light);

  & svg {
    height: 100%;
    width: 100%;
  }
}

.contextbar {
  display: flex;

  padding: 8px;

  align-items: center;
  gap: 8px;

  background: var(--color-dark);
  border-radius: 4px;
}

.contextbar__button {
  display: flex;

  padding: 8px;

  justify-content: center;
  align-items: center;

  background: var(--color-dark);
  border-radius: 4px;

  color: var(--color-light-gray);

  @include mixins.transition(ease 0.2s all);

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:enabled {
    background: var(--color-gray);
    color: var(--color-light);
  }

  &_active,
  &_active:hover:enabled {
    background: var(--color-primary);
    color: var(--color-light);
  }

  &_delete {
    align-items: center;
    gap: 10px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
