.container {
  display: flex;

  height: 100%;
  width: 100%;

  flex-grow: 1;
  flex-direction: column;
}

.flow-wrapper {
  display: flex;
  position: relative;

  flex-grow: 1;
}
