@use 'styles/common/mixins' as mixins;

.footer {
  width: 100%;
  padding: 20px 0 30px;

  background-color: var(--color-dark);
}

.container {
  display: flex;

  height: 100%;

  max-width: 1046px;
  padding: 0 20px;

  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
}

.left {
  display: flex;

  flex-grow: 2;
  padding: 20px 0;

  flex-direction: column;
  gap: 20px;
}

.logo {
  max-width: 180px;
  width: 100%;

  & img {
    width: 100%;
  }
}

.policies {
  display: flex;

  flex-direction: column;
  gap: 10px;
}

.policy {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-light);

  // text-decoration-line: underline;

  opacity: 0.5;
}

.right {
  display: flex;

  flex-grow: 1;
  padding: 20px 0;

  flex-direction: column;
  gap: 20px;
}

.navigation {
  display: flex;

  padding: 35.5px 0;

  gap: 48px;
}

.navigation__link {
  background: none;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--color-light);

  user-select: none;
  cursor: pointer;

  @include mixins.transition(ease 0.3s all);

  &:hover {
    color: var(--color-gray);
  }
}

.contacts {
  display: flex;

  justify-content: space-between;
  gap: 80px;
}

.contacts__left {
  display: flex;

  flex-direction: column;
  gap: 10px;
}

.microsoft {
  display: flex;

  flex-direction: column;

  &_mobile {
    margin-bottom: 32px;
  }

  &__subtitle {
    margin-bottom: 10px;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-light);
  }

  &__logo {
    width: 200px;
  }
}

.contacts__right {
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.links {
  display: flex;

  gap: 16px;
}

.link {
  height: 24px;
  width: 24px;

  color: var(--color-gray);
}

.rights {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-light);
}

.menu {
  display: flex;

  width: 328px;

  flex-direction: column;

  .header {
    margin-bottom: 40px;
    padding: 0;
  }

  .navigation {
    display: flex;

    margin-bottom: 32px;
    padding: 0;

    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    &__link {
      background: none;

      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: var(--color-light);

      user-select: none;
      cursor: pointer;

      @include mixins.transition(ease 0.3s all);

      &:hover {
        color: var(--color-gray);
      }
    }
  }

  .contacts {
    display: flex;

    margin-bottom: 32px;
    padding: 0;

    flex-direction: column;
    gap: 10px;
  }

  .address,
  .tel,
  .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--color-light);
  }

  .links {
    display: flex;

    margin-bottom: 40px;
    padding: 0;

    gap: 16px;
  }

  .link {
    height: 24px;
    width: 24px;

    color: var(--color-gray);
  }

  .policies {
    display: flex;

    margin-bottom: 20px;
    padding: 0;

    flex-direction: column;
    gap: 10px;
  }

  .policy {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--color-light);

    // text-decoration-line: underline;

    opacity: 0.5;
  }

  .rights {
    margin-bottom: 20px;
    padding: 0;

    max-width: 320px;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-light);
  }
}
