.container {
  display: flex;
  position: relative;

  width: 100%;
}

.reactflow {
  width: 100%;
}
