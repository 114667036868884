@use 'styles/common/mixins' as mixins;

.section {
  width: 100%;
  padding: 60px 0;

  background-color: var(--color-bg-light);

  @media screen and (max-width: 800px) {
    padding-top: 50px;
  }
}

.container {
  display: flex;

  height: 100%;

  max-width: 1046px;
  padding: 0 20px;

  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    padding: 0 88px;
  }

  @media screen and (max-width: 924px) {
    padding: 0 20px;

    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
}

.contacts,
.support {
  display: flex;

  max-width: 494px;
  width: 100%;

  width: 100%;
  flex-direction: column;
}

.title {
  margin-bottom: 40px;

  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: var(--color-dark);
}

.address,
.tel,
.email {
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
}

.tel {
  margin-bottom: 10px;
  color: var(--color-dark);
}

.email {
  color: var(--color-blue);
}

.form {
  display: flex;

  flex-direction: column;
}

.form__name,
.form__email,
.form__message {
  margin-bottom: 16px;
}

.form__condition {
  margin-bottom: 40px;
}

.condition {
  display: flex;

  flex-direction: column;

  &__button {
    display: flex;

    background: none;
    user-select: none;

    &:disabled {
      opacity: 0.3;
      cursor: auto;
    }
  }

  &__icon {
    height: 20px;
    width: 20px;

    margin-right: 8px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: start;
  }

  &__text {
    margin-right: 4px;
    color: var(--color-gray);
  }

  &__link {
    color: var(--color-blue);
  }

  &__button {
    &_error {
      margin-bottom: 3px;
    }
  }
}

.error {
  display: block;

  margin-left: 20px;

  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: var(--color-error);
}

.form__button {
  max-width: 162px;
  width: 100%;
}
