.container {
  display: flex;

  flex-grow: 1;

  padding: 15px;

  flex-direction: column;
  gap: 15px;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 20px;
}
