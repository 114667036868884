.container {
  width: 480px;
}

.form {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.subtitle {
  margin-bottom: 12px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-gray);
}

.select-project {
  margin-bottom: 32px;
}

.buttons {
  display: flex;

  gap: 12px;
}

.button-cancel,
.button-submit {
  width: 240px;
}
