.container {
  width: 640px;
}

.form {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 24px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.subtitle {
  margin-bottom: 12px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-gray);
}

.bold {
  color: var(--color-dark);
}

.field {
  display: flex;

  width: 100%;
  padding-bottom: 32px;
}

.input-name {
  border-radius: 8px 0px 0px 8px;
  border-right: none;
}

.select-role {
  max-width: 180px;

  &__header {
    padding: 13px 20px;
    border-radius: 0px 8px 8px 0px;
  }
}

.buttons {
  display: flex;

  gap: 12px;
}

.button-cancel,
.button-submit {
  width: 240px;
}
