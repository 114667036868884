.header {
  display: flex;

  margin-bottom: 30px;

  align-items: center;
  gap: 30px;
}

.name {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.21px;
  color: var(--color-dark);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.links {
  display: flex;
  gap: 8px;

  margin-right: auto;

  flex-shrink: 0;
}

.link {
  display: flex;

  padding: 8px 8px 6px 8px;

  align-items: center;
  gap: 8px;

  border-bottom: 2px solid transparent;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-gray);

  &_active {
    border-color: var(--color-gray);
    color: var(--color-dark);

    & .icon {
      color: var(--color-primary);
    }
  }
}

.icon {
  height: 24px;
  width: 24px;
}

.button-edit {
  height: 40px;
  flex-shrink: 0;

  background: var(--color-light) !important;
  color: var(--color-gray);
}
