@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  flex-direction: column;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);

  overflow: hidden;
}

.header {
  display: flex;

  height: 60px;

  padding: 13px;

  background: var(--color-light-light-gray);

  align-items: center;

  user-select: none;
  @include mixins.transition(ease 0.2s all);

  &:hover {
    & .header__icon {
      color: var(--color-primary);
    }
    & .header__amount {
      background: var(--color-primary);
    }
  }

  &_active {
    border-radius: 10px;

    & .header__icon {
      color: var(--color-primary);
    }
    & .header__stroke {
      transform: rotate(180deg);
    }
  }
}

.header__icon {
  margin-right: 20px;
  color: var(--color-dark);

  @include mixins.transition(ease 0.3s all);
}

.header__title {
  margin-right: auto;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--color-dark);
  text-align: start;
}

.header__amount {
  display: flex;

  margin-right: 10px;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;

  background: var(--color-gray);
  border-radius: 15px;

  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-light);
  text-align: center;

  @include mixins.transition(ease 0.3s all);
}

.header__stroke {
  color: var(--color-dark);
  @include mixins.transition(ease 0.3s all);
}

.list {
  display: flex;

  padding: 5px 0 15px;

  flex-direction: column;
}

.button-create {
  margin-top: 10px;
  margin-left: 15px;

  align-self: flex-start;
}
