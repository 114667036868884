@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  width: 800px;
  
  flex-direction: column;
  align-items: center;
}

.containerForm {
  width: 560px;
}

.form {
  display: flex;

  margin-bottom: 32px;

  flex-direction: column;
}

.title {
  margin-bottom: 12px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.title-subtitle {
  margin: 0 auto;

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
}

.subtitle {
  padding: 24px 0 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.01em;
}

.input {
  width: 272px;
}

.input-message {
  padding-bottom: 32px;
  height: 166px;
}

.buttonSubmit {
  display: flex;

  width: 300px;

  align-items: center;
  gap: 12px;

  margin: 0 auto;
}

.button-delete {
  background: none;

  align-self: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--color-blue);

  user-select: none;
  @include mixins.transition(ease 0.3s all);

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  &:hover:enabled {
    color: var(--color-primary);
  }
}
