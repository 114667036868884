@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  width: 740px;
  
  flex-direction: column;
  align-items: center;
}

.containerForm {
  width: 480px;
}

.form {
  display: flex;

  flex-direction: column;
}

.title {
  margin-bottom: 12px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.title-subtitle {
  margin: 0 auto;

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
}

.subtitle {
  margin-bottom: 28px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-gray);
}

.input {
  width: 480px;
  margin-bottom: 36px;
}

.input-message {
  padding-bottom: 32px;
  height: 166px;
}

.buttons {
  display: flex;

  gap: 12px;
}
