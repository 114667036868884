@use 'styles/common/mixins' as mixins;

.badge {
  display: flex;

  height: 32px;
  width: 32px;

  padding-top: 4px;

  justify-content: center;
  align-items: center;

  background: var(--color-bg-light);
  border: 2px solid var(--color-gray);
  border-radius: 20px;

  font-size: 18px;
  line-height: 21;
  font-weight: 500;
  color: var(--color-gray);

  @include mixins.transition(ease 0.3s all);
  user-select: none;
}

.tooltip {
  display: flex;
  z-index: 5;

  padding: 0;

  flex-direction: column;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  user-select: none;

  .name {
    padding: 8px 12px;

    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--color-light-gray);
  }
}
