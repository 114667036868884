.container {
  display: flex;

  padding: 15px;

  flex-direction: column;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 20px;
}

.title {
  margin-bottom: 10px;

  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: var(--color-dark);
}

.list {
  display: flex;

  flex-direction: column;
  gap: 10px;
}
