@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  width: 310px;
  flex: 0 0 auto;

  flex-direction: column;
}

.lists {
  display: flex;

  margin-bottom: 30px;

  padding: 13px;

  flex-direction: column;
  gap: 10px;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 20px;
}

.header {
  padding: 15px 30px;

  &__title {
    max-width: 250px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-dark);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.buttons {
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.button {
  display: flex;

  padding: 30px 10px 25px;

  height: 130px;
  width: 145px;

  flex-direction: column;
  align-items: center;

  background: var(--color-light);
  border-radius: 20px;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);

  user-select: none;
  @include mixins.transition(ease 0.3s all);

  &:hover {
    background: var(--color-light-light-gray);

    .icon {
      background: var(--color-light);
    }
  }

  &_active,
  &:active {
    background: var(--color-light-light-gray);

    .icon {
      background: var(--color-light);
    }
    .text {
      color: var(--color-primary);
    }
  }
}

.icon {
  display: flex;

  margin-bottom: 15px;

  width: 40px;
  height: 40px;

  justify-content: center;
  align-items: center;

  background: var(--color-light-light-gray);
  border-radius: 50%;

  & svg {
    height: 24px;
    width: 24px;

    color: var(--color-primary);
  }

  @include mixins.transition(ease 0.3s all);
}

.text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.14px;
  color: var(--color-gray);

  @include mixins.transition(ease 0.3s all);
}
