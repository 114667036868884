.section {
  width: 100%;

  padding: 90px 0 45px;

  @media screen and (max-width: 1024px) {
    padding: 60px 0 30px;
  }

  @media screen and (max-width: 930px) {
    padding: 32px 0 16px;
  }
}

.container {
  display: flex;

  height: 100%;

  max-width: 1220px;
  padding: 0 20px;

  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}

.header {
  margin-bottom: 45px;

  @media screen and (max-width: 1024px) {
    max-width: 560px;
  }
}

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.cards {
  display: flex;

  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
}

.card {
  display: flex;

  max-width: 282px;

  flex-direction: column;
  gap: 24px;

  &__image {
    max-width: 80px;
    width: 100%;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: var(--color-gray);
  }

  @media screen and (max-width: 1024px) {
    max-width: 229.33px;
  }

  @media screen and (max-width: 930px) {
    max-width: 288px;
  }
}
