.container {
  display: flex;

  height: 100%;
  width: 100%;
  flex-grow: 1;

  padding-top: 28px;

  justify-content: center;
}

.content {
  display: flex;

  max-width: 800px;
  width: 100%;

  flex-direction: column;
  align-items: center;

  margin-bottom: 64px;
}

.text-container {
  max-width: 800px;

  .text-heading {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: justify;
  }

  .text-ul {
    margin-left: 25px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: justify;
  }

  .text-li {
    margin-left: 25px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: justify;
  }
  
  .text-block {
    margin-bottom: 34px;
  }
  
  .text-indent {
    margin-bottom: 16px;
  }

  .link-block {
    color: var(--Link, #4971FF);
    text-align: justify;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    text-decoration-line: underline;
  }
}

.content__title {
  margin-bottom: 24px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.title-block {
  display: flex;

  width: 100%;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .main-title {
    margin-bottom: 12px;
  
    color: var(--Dark, #2D3C59);
    font-family: Roboto;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 142.857% */
    letter-spacing: 0.21px;
  }

  .right-title {
    margin-bottom: 12px;

    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #6B7A99;
  }
}

.button-delete {
  position: absolute;

  height: 24px;
  width: 24px;

  top: 85px;
  right: 30px;

  background: none;

  color: var(--color-gray);
}

.span-button {
  color: #4971FF;
  cursor: pointer;
}
