.section {
  width: 100%;

  padding: 31px 0 77px;

  user-select: none;

  @media screen and (max-width: 1024px) {
    padding: 21px 0 60px;
  }
}

.container {
  display: flex;

  height: 100%;

  max-width: 1220px;
  padding: 0 20px;

  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}

.card {
  display: flex;

  max-width: 838px;
  width: 100%;

  padding: 80px 0 60px;

  justify-content: center;

  background-color: var(--color-dark-gray);
  border-radius: 24px;
  background-image: url('~assets/images/png/landing/testimonials-bg-light.png');
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  display: flex;

  max-width: 518px;

  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 16px;

  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-light);
}

.subtitle {
  margin-bottom: 40px;

  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
  color: var(--color-light-light-gray);
}

.button {
  max-width: 200px;
}
