.logo {
  margin-right: 16px;

  height: 32px;
  width: 32px;

  & img {
    height: 100%;
    width: 100%;
  }
}

.board-info {
  margin-right: auto;
}

.button-premium {
  margin-right: 20px;
}

.share {
  margin-right: 24px;
}
