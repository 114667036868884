@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  padding: 10px 15px;

  align-items: center;

  border-radius: 10px;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: var(--color-dark);
}
