.container {
  display: flex;

  align-items: center;
  gap: 10px;
}

.title {
  flex-shrink: 0;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: var(--color-dark);
}

.slider {
  flex: 1 1 auto;
}

.slide {
  // max-width: 140px;
  width: auto;
}

.card {
  display: flex;

  padding: 10px 20px 10px 15px;

  justify-content: center;
  align-items: center;
  gap: 10px;

  background: var(--color-green);
  border-radius: 8px;

  user-select: none;

  &_unavailable {
    opacity: 0.5;
    cursor: auto;
  }
}

.icon {
  height: 20px;
  width: 20px;

  color: var(--color-light);

  & svg {
    height: 100%;
    width: 100%;
  }
}

.name {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.14px;
  color: var(--color-light);
}
