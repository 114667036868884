.container {
  display: flex;
}

.board-list {
  display: flex;

  flex-wrap: wrap;
  gap: 10px;
}

.placeholder {
  margin: 100px auto 130px;

  max-width: 339px;
  width: 100%;

  & img {
    height: 100%;
    width: 100%;
  }
}
