.section {
  width: 100%;

  padding: 60px 0 50px;

  user-select: none;

  @media screen and (max-width: 1024px) {
    padding: 50px 0;
  }

  @media screen and (max-width: 900px) {
    padding: 34px 0 18px;
  }
}

.container {
  display: flex;

  height: 100%;

  max-width: 1046px;
  padding: 0 20px;

  flex-direction: column;

  .header {
    margin-bottom: 48px;

    @media screen and (max-width: 900px) {
      margin-bottom: 29px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.03em;
    color: var(--color-dark);
  }

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}

.plans {
  display: flex;

  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
}

.plan {
  display: flex;
  flex-direction: column;

  max-width: 322px;
  width: 100%;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.09);
  border-radius: 10px;

  @media screen and (max-width: 1024px) {
    max-width: 317.33px;
  }

  .header {
    margin-bottom: 0;
    padding: 20px 20px 0 20px;
  }
  .title {
    padding-bottom: 24px;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: start;

    border-bottom: 1px solid var(--color-light-gray);
  }

  .subtitle {
    padding: 24px 20px 20px 20px;

    font-weight: 500;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.01em;
  }

  .price {
    min-height: 160px;
    padding: 20px;
  }

  .features {
    padding: 20px;

    &__button {
      display: flex;

      margin-bottom: 24px;

      align-items: center;
      gap: 4px;

      background: none;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;

      & svg {
        height: 20px;
        width: 20px;
      }
    }

    &__list {
      display: flex;

      margin-bottom: 24px;

      flex-direction: column;
      gap: 16px;

      & span {
        height: 20px;
        width: 20px;
      }
    }

    &__item {
      display: flex;

      gap: 12px;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }

  .button {
    display: flex;

    width: 100%;
    padding: 16px;

    justify-content: center;
    align-items: center;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &_free {
    background: var(--color-light);

    .title,
    .subtitle {
      color: var(--color-dark);
    }

    .price {
      background: var(--color-light-light-gray);

      &__title {
        font-weight: 700;
        font-size: 36px;
        line-height: 130%;
        letter-spacing: -0.03em;
        color: var(--color-dark);
      }
    }

    .features {
      background: var(--color-light);

      &__button {
        color: var(--color-gray);
      }

      &__item {
        color: var(--color-gray);

        & span {
          color: var(--color-green);
        }
      }
    }

    .button {
      background: var(--color-bg-light);
      color: var(--color-dark);
    }
  }

  &_basic {
    background: var(--color-purple);

    .title,
    .subtitle {
      color: var(--color-light);
    }

    .price {
      display: flex;

      background: rgba(0, 0, 0, 0.1);

      align-items: flex-start;
      gap: 10px;

      &__cost {
        font-weight: 700;
        font-size: 48px;
        line-height: 56px;
        color: var(--color-light);
      }
      &__text {
        display: flex;

        flex-direction: column;
        gap: 16px;
      }
      &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: var(--color-light);
      }
      &__subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: var(--color-light);
      }
    }

    .features {
      &__button {
        color: var(--color-light);
      }

      &__item {
        color: var(--color-light);

        & span {
          color: var(--color-light);
        }
      }
    }

    .button {
      background: var(--color-light);
      color: var(--color-dark);
    }
  }

  &_enterprise {
    background: var(--color-blue);

    .title,
    .subtitle {
      color: var(--color-light);
    }

    .price {
      display: flex;

      background: #3059e8;

      flex-direction: column;
      gap: 4px;

      &__title {
        font-weight: 700;
        font-size: 36px;
        line-height: 130%;
        letter-spacing: -0.03em;
        color: var(--color-light);
      }
      &__subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: var(--color-light);
      }
    }

    .features {
      &__button {
        color: var(--color-light);
      }

      &__item {
        color: var(--color-light);

        & span {
          color: var(--color-light);
        }
      }
    }

    .button {
      padding: 15px;

      background: none;
      border: 1px solid var(--color-light);

      color: var(--color-light);
    }
  }
}
