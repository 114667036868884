.logo {
  margin-right: auto;

  height: 44px;
  width: 128px;

  & img {
    height: 100%;
    width: 100%;
  }
}

.controls {
  display: flex;

  gap: 12px;
}

.button {
  height: 36px;
}
