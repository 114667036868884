@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  max-height: none;
  max-width: none;

  align-items: center;
  gap: 20px;

  border: none;
  background: none;
  box-shadow: none;
}

.block {
  display: flex;

  background: var(--color-light);
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);

  overflow: hidden;
}

.button {
  display: flex;

  height: auto;
  width: auto;

  padding: 8px;

  justify-content: center;
  align-items: center;

  border: none;
  background: none;
  box-shadow: none;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.15em;
  color: var(--color-gray);

  @include mixins.transition(ease 0.2s all);

  &:hover:enabled,
  &_active:enabled {
    background: var(--color-primary);
    color: var(--color-light);
  }

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  &_undo {
    border-right: 1px solid rgb(107, 122, 153, 0.09);
  }

  &_percentage {
    border-right: 1px solid rgb(107, 122, 153, 0.09);
    border-left: 1px solid rgb(107, 122, 153, 0.09);

    cursor: auto;

    &:hover,
    &_active {
      background: none;
      color: var(--color-gray);
    }

    & span {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.15em;
    }
  }
}

.icon {
  height: 24px;
  width: 24px;

  & svg {
    max-height: none;
    max-width: none;

    height: 24px;
    width: 24px;
  }
}
