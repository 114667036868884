.container {
  display: flex;

  height: 100%;
  width: 100%;
  flex-grow: 1;

  padding-top: 128px;

  justify-content: center;
}

.content {
  display: flex;

  max-width: 438px;
  width: 100%;

  flex-direction: column;
  align-items: center;
}

.content__title {
  margin-bottom: 24px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.form {
  display: flex;

  max-width: 328px;
  width: 100%;

  flex-direction: column;
  align-items: center;
}

.form__title {
  margin-bottom: 12px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-gray);
}

.form__fields {
  display: flex;

  margin-bottom: 12px;

  width: 100%;

  flex-direction: column;
  gap: 12px;
}

.button-delete {
  position: absolute;

  height: 24px;
  width: 24px;

  top: 85px;
  right: 30px;

  background: none;

  color: var(--color-gray);
}
