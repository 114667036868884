.container {
  display: flex;

  min-height: 56px;
  max-height: 56px;
  width: 100%;
  flex: 1;

  align-items: center;

  gap: 10px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.01em;

  color: var(--color-gray);
}

.line {
  height: 1px;
  width: 100%;
  flex: 1;

  background-color: var(--color-gray);
}
