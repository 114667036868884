@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  flex-grow: 1;

  padding: 15px;

  flex-direction: column;
  gap: 10px;

  border: 2px solid var(--color-light-light-gray);
  border-radius: 20px;

  &_active {
    background: var(--color-light-light-blue);
    border: 2px dashed var(--color-blue);
  }

  user-select: none;
}

.header {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.button-upload {
  height: 40px;
}

.list {
  display: flex;

  flex-wrap: wrap;
  gap: 4px;
}

.input-upload {
  display: none;
}

.placeholder {
  margin: 100px auto 130px;

  max-width: 339px;
  width: 100%;

  & img {
    height: 100%;
    width: 100%;
  }
}
