.termsAndPolicy {
  width: 330px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-bg-dark);

  letter-spacing: 0.14px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}
