@use 'styles/common/mixins' as mixins;

.logo {
  margin-right: 32px;

  height: 44px;
  width: 128px;

  & img {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 1023px) {
    margin-right: 0;
  }
}

.navigation {
  display: flex;

  margin-right: auto;

  padding: 0 16px;

  align-items: center;
  gap: 48px;

  &__link {
    background: none;

    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-dark);

    user-select: none;
    cursor: pointer;

    @include mixins.transition(ease 0.3s all);

    &:hover {
      color: var(--color-gray);
    }
  }
}

.burger {
  height: 44px;
  width: 44px;

  background: none;
  color: var(--color-dark);
}

.menu {
  display: flex;
  position: fixed;
  z-index: 5;

  top: 0;
  bottom: 0;
  left: -360px;

  width: 360px;

  flex-direction: column;

  background: var(--color-light);

  @include mixins.transition(ease 0.3s all);

  &_active {
    left: 0;
  }

  .header {
    display: flex;

    margin-bottom: 20px;
    padding: 12px 16px;

    align-items: center;
    justify-content: space-between;
  }

  .contacts {
    display: flex;

    margin-bottom: 40px;
    padding: 0 20px;

    flex-direction: column;
    gap: 10px;
  }

  .address,
  .tel,
  .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--color-dark);
  }

  .links {
    display: flex;

    margin-bottom: 30px;
    padding: 0 20px;

    gap: 16px;
  }

  .link {
    height: 24px;
    width: 24px;

    color: var(--color-gray);
  }

  .rights {
    margin-bottom: 30px;
    padding: 0 20px;

    max-width: 320px;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-dark);
  }

  .policies {
    display: flex;

    padding: 0 20px;

    flex-direction: column;
    gap: 10px;
  }

  .policy {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--color-dark);

    // text-decoration-line: underline;

    opacity: 0.5;
  }
}

.backdrop {
  position: fixed;
  z-index: 5;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
}

.menu__navigation {
  display: flex;

  margin-bottom: 20px;
  padding: 0 20px;

  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  &__link {
    background: none;

    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-dark);

    user-select: none;
    cursor: pointer;

    @include mixins.transition(ease 0.3s all);

    &:hover {
      color: var(--color-gray);
    }
  }
}

.controls {
  display: flex;

  gap: 12px;
}

.button {
  height: 36px;
}
