@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  position: relative;

  width: 100%;

  flex-direction: column;

  user-select: none;

  &:disabled {
    opacity: 0.3;
    background-color: var(--color-bg-light);
  }

  &_small {
    max-width: 328px;
  }
  &_big {
    max-width: none;
    flex: 1;
  }
}

.label {
  padding: 8px 20px;

  background: var(--color-light);
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  outline: none;

  cursor: text;

  @include mixins.transition(ease 0.2s all);

  &_small {
    max-width: 404px;
  }
  &_big {
    max-width: none;
  }

  &_focus {
    border-color: var(--color-primary);
  }

  &_error {
    margin-bottom: 3px;

    background: var(--color-light);
    border-color: var(--color-error);
  }
}

.textarea {
  display: block;

  width: 100%;

  font-size: 18px;
  line-height: 24px;

  outline: none;

  &_resize-none {
    resize: none;
  }

  &::placeholder {
    color: var(--color-gray);
  }

  &::-webkit-resizer {
    display: none;
  }
}

.errorMessage {
  display: block;

  margin-left: 20px;

  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: var(--color-error);
}
