@use 'styles/common/mixins' as mixins;

.container {
  height: 38px;
  width: 100%;

  background: var(--color-light-light-gray);
  border: 1px solid var(--color-light-gray);

  @include mixins.transition(ease 0.3s all);

  &_selected {
    border-color: var(--color-primary);
    border-style: dashed;
    background: var(--color-light);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);

    cursor: crosshair;
  }
}
