@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  padding: 2px 15px;

  align-items: center;

  background: var(--color-light);
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(136, 51, 255, 0.1);
}

.avatar {
  display: flex;

  margin-right: 8px;

  min-height: 40px;
  height: 40px;
  min-width: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  background: var(--color-bg-light);
  border-radius: 20px;

  color: var(--color-gray);

  & svg {
    height: 24px;
    width: 24px;
  }
}

.name {
  max-width: 240px;
  width: 100%;

  margin-right: auto;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--color-dark);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.email {
  max-width: 240px;
  width: 100%;

  margin-right: 10px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-blue);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-role {
  margin-right: 10px;
}

.buttonOptions {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;

  background: none;
  color: var(--color-gray);
}

.tooltip {
  z-index: 5;

  padding: 0;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  overflow: hidden;

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;

    width: 100%;
    padding: 12px 20px;

    gap: 8px;

    background: none;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-light-gray);

    @include mixins.transition(ease 0.3s all);

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }

    &:hover:enabled {
      background: var(--color-gray);
      color: var(--color-light);
    }

    &_active,
    &_active:hover:enabled {
      background: var(--color-primary);
      color: var(--color-light);
    }
  }
}
