@use 'styles/common/mixins' as mixins;

.contextbar {
  display: flex;

  height: 56px;

  padding: 8px;

  align-items: center;
  gap: 8px;

  background: var(--color-dark);
  border-radius: 4px;
}

.contextbar__button {
  display: flex;

  padding: 8px;

  justify-content: center;
  align-items: center;

  background: var(--color-dark);
  border-radius: 4px;

  color: var(--color-light-gray);

  @include mixins.transition(ease 0.2s all);

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:enabled {
    background: var(--color-gray);
    color: var(--color-light);
  }

  &_active,
  &_active:hover:enabled {
    background: var(--color-blue);
    color: var(--color-light);
  }

  &_delete {
    align-items: center;
    gap: 10px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.contextbar__color {
  height: 24px;
  width: 24px;

  border-radius: 50%;
}

.tooltip {
  padding: 8px;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  user-select: none;
}

.tooltip__list {
  display: flex;

  max-width: 160px;

  flex-wrap: wrap;
}

.tooltip__item {
  display: flex;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  background: none;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:hover:enabled {
    background: var(--color-gray);

    & .key {
      background: var(--color-dark);
    }
  }
}

.tooltip__circle {
  height: 20px;
  width: 20px;

  border-radius: 50%;

  border: 2px solid transparent;

  &_active,
  &_active:hover:enabled {
    border-color: var(--color-primary);
  }
}
