.container {
  display: flex;

  flex-direction: column;

  user-select: none;
}

.header {
  display: flex;

  padding: 10px 15px;

  justify-content: space-between;
  align-items: center;
  gap: 10px;

  &_active {
    background: var(--color-light-light-gray);

    .stroke {
      display: block;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: var(--color-dark);
}

.stroke {
  display: none;

  height: 20px;
  width: 20px;

  color: var(--color-primary);
}

.links {
  display: flex;

  padding: 10px 15px 10px 20px;

  flex-direction: column;
  gap: 8px;
}

.link {
  display: flex;

  padding: 4px 0;

  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: var(--color-dark);

  &_active {
    color: var(--color-primary);
  }
}

.icon {
  height: 16px;
  width: 16px;
}
