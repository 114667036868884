@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;

  width: 64px;
  padding: 82px 8px 18px 8px;

  flex-direction: column;

  background: var(--color-dark);
}

.team-list {
  display: flex;

  max-height: calc(100% - 48px);
  width: 100%;
}

.slider {
  width: 100%;
}

.slide {
  height: 48px;
  width: 48px;
}

.tooltip {
  z-index: 5;

  padding: 7px 11px;

  background: var(--color-light-light-gray);
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);

  opacity: 1 !important;

  &__text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.12px;
    color: var(--color-dark);
  }
}

.tooltip {
  z-index: 5;

  padding: 8px 12px;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  user-select: none;

  .name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--color-light-gray);
  }
}

.button-create {
  display: flex;

  height: 48px;
  width: 48px;

  justify-content: center;
  align-items: center;

  background: var(--color-dark-gray);
  border-radius: 100%;

  &__icon {
    height: 24px;
    width: 24px;

    color: var(--color-light);
  }
}
