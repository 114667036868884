@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  height: 180px;
  min-width: 220px;
  max-width: 240px;
  flex: 1 0 0;

  padding: 15px;

  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  background: var(--color-light);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;

  cursor: pointer;
  user-select: none;
  @include mixins.transition(ease 0.3s all);

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }
}

.icon {
  height: 48px;
  width: 48px;
}

.name {
  display: -webkit-box;

  max-width: 100%;

  font-size: 18px;
  line-height: 24px;
  text-align: start;
  color: var(--color-dark);

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // number of lines to show

  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.used {
  max-width: 100%;
  padding: 4px 8px;

  background: var(--color-bg-light);
  border-radius: 12px;

  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: var(--color-blue);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
