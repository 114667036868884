.container {
  display: flex;

  height: 54px;
  width: 188px;

  padding: 15px;

  justify-content: center;
  align-items: center;
  gap: 15px;

  background: var(--color-green);
  border-radius: 10px;

  user-select: none;

  &_unavailable {
    opacity: 0.5;
    cursor: auto;
  }
}

.icon {
  height: 24px;
  width: 24px;

  color: var(--color-light);
}

.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-light);
}
