.container {
  display: flex;

  height: 100%;
  width: 100%;
  flex-grow: 1;

  padding-top: 80px;
  padding-bottom: 40px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;

  max-width: 328px;
  width: 100%;
  flex-grow: 1;

  padding-top: 48px;

  flex-direction: column;
  align-items: center;
}

.success {
  display: flex;
  flex-direction: column;

  margin-bottom: auto;

  width: 100%;

  &__title {
    margin-bottom: 32px;

    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: var(--color-dark);
  }

  &__subtitle {
    margin-bottom: 12px;

    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray);
  }
}

.form {
  display: flex;

  margin-bottom: 32px;

  width: 100%;

  flex-direction: column;
  align-items: center;
}

.form__title {
  margin-bottom: 32px;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.form__fields {
  display: flex;

  margin-bottom: 12px;

  width: 100%;

  flex-direction: column;
  gap: 12px;
}

.backToLogin {
  margin-bottom: auto;

  padding-bottom: 40px;

  &__link {
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 4px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.01em;
  }
}
