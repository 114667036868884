@use 'styles/common/mixins' as mixins;

.section {
  width: 100%;

  padding: 53px 0;

  @media screen and (max-width: 1024px) {
    padding-top: 23px;
  }

  @media screen and (max-width: 800px) {
    padding: 16px 0;
  }
}

.container {
  display: flex;

  height: 100%;

  max-width: 1220px;
  padding: 0 20px;

  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 800px) {
    padding: 0;
  }
}

.slider {
  display: flex;

  margin-bottom: 53px;
  padding: 60px 85px 83px;

  flex-direction: column;

  background: var(--color-bg-light);
  border-radius: 80px;
  box-shadow: inset -10px 0px 10px rgba(0, 0, 0, 0.03);

  @media screen and (max-width: 1024px) {
    padding: 60px 72px 53px;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 62px;
    padding: 38px 16px 29px;

    border-radius: 36px;
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 32px;
  }

  .header {
    margin-bottom: 48px;

    @media screen and (max-width: 900px) {
      margin-bottom: 32px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.03em;
    color: var(--color-dark);

    @media screen and (max-width: 530px) {
      text-align: start;
    }
  }

  .swiper {
    margin-bottom: 67px;

    width: 100%;

    padding-bottom: 5px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 66px;
    }

    @media screen and (max-width: 900px) {
      margin-bottom: 32px;
    }
  }

  .slide {
    max-width: 219px;
  }

  .card {
    display: flex;

    height: 70px;
    width: 100%;

    padding: 15px 10px;

    align-items: center;
    gap: 15px;

    background: var(--color-light);
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.15);

    @include mixins.transition(ease 0.3s all);

    &_active:enabled {
      background: var(--color-primary);

      .card__icon {
        background: none;
      }
      .card__text {
        color: var(--color-light);
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }

    &__icon {
      display: flex;

      height: 40px;
      width: 40px;

      justify-content: center;
      align-items: center;

      background: var(--color-green);
      border-radius: 20px;
      color: var(--color-light);

      &_mindmap {
        background: var(--color-green);
      }
      &_roadmap {
        background: var(--color-light-blue);
      }
      &_flowchart {
        background: var(--color-orange);
      }
      &_diagram {
        background: var(--color-yellow);
      }
      &_wbs {
        background: #674fff;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 21px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--color-gray);
    }

    @media screen and (max-width: 900px) {
      height: 60px;
    }
  }

  .content {
    display: flex;

    align-items: flex-start;
    gap: 20px;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      gap: 33px;
    }
  }

  .left {
    &__title {
      margin-bottom: 16px;
      max-width: 461px;

      font-weight: 500;
      font-size: 21px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: var(--color-dark);
    }

    &__text {
      margin-bottom: 16px;
      max-width: 492px;

      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: var(--color-gray);
    }
  }

  .button-start {
    width: 172px;

    background: var(--color-green) !important;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-light);
  }

  .content__image {
    max-width: 494px;
    width: 100%;

    padding: 10px;

    background: var(--color-light);
    border-radius: 10px;
    box-shadow: 0px 10px 30px 0px rgba(38, 51, 77, 0.05);

    & img {
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      max-width: 414px;
    }

    @media screen and (max-width: 900px) {
      max-width: 328px;
    }
  }
}

.features {
  display: flex;

  flex-direction: column;
  gap: 70px;

  @media screen and (max-width: 900px) {
    align-items: center;
    gap: 32px;
  }
}

.feature {
  display: flex;

  justify-content: center;
  gap: 100px;

  &:nth-child(even) {
    padding-left: 80px;
  }

  @media screen and (max-width: 1024px) {
    gap: 90px;

    &:nth-child(even) {
      padding-left: 72px;
    }
  }

  @media screen and (max-width: 900px) {
    &:nth-child(even) {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 16px;
  }

  &__image {
    max-width: 494px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 414px;
    }

    @media screen and (max-width: 800px) {
      max-width: 320px;
    }

    & img {
      width: 100%;
    }
  }
  &__content {
    max-width: 414px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 342px;
    }

    @media screen and (max-width: 800px) {
      max-width: 328px;
    }
  }
  &__title {
    margin-bottom: 16px;
  }
}

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: var(--color-dark);
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  list-style-position: outside;
  padding: 0;
  color: var(--color-gray);
}
