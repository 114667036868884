@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  position: relative;

  flex-direction: column;

  background: none;
}

.header {
  display: flex;

  // min-width: 165px;

  background: var(--color-light);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;

  align-items: center;
  gap: 10px;

  &:hover {
    .stroke {
      color: var(--color-dark);
    }
  }

  & .input {
    max-width: 200px;
    width: 100%;

    background: var(--color-light);

    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-dark);
  }

  & .icon-wrapper {
    display: flex;

    width: 24px;
    height: 24px;
  }

  & .icon {
    color: var(--color-dark);
  }

  & .name {
    max-width: 200px;
    width: 100%;

    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-dark);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    user-select: none;
  }

  & .stroke {
    display: flex;

    height: 16px;
    width: 16px;

    justify-content: center;
    align-items: center;

    background: none;
    color: var(--color-gray);
  }
}

.tooltip {
  z-index: 5;

  min-width: 100%;
  max-width: 200px;

  padding: 8px 0;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  overflow: hidden;

  & .team-project {
    display: flex;

    width: 100%;
    padding: 8px 20px 12px;

    flex-direction: column;
    gap: 8px;

    border-bottom: 1px solid var(--color-gray);
  }

  & .team,
  & .project {
    display: flex;

    flex-direction: column;
  }

  & .team__title,
  & .project__title {
    margin-bottom: 4px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--color-light-gray);
    text-transform: uppercase;

    user-select: none;
  }

  & .team__name,
  & .project__name {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-light);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .move {
    width: 100%;
    padding: 6px 0;

    border-bottom: 1px solid var(--color-gray);
  }

  & .move__button,
  & .controls__button,
  & .delete__button {
    width: 100%;
    padding: 6px 20px;

    background: none;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-light);
    text-align: start;

    @include mixins.transition(ease 0.3s all);

    &:disabled {
      opacity: 0.3;
      cursor: auto;
    }

    &:hover:enabled {
      background: var(--color-gray);
    }
  }

  & .controls {
    display: flex;

    width: 100%;
    padding: 16px 0;

    flex-direction: column;
    gap: 8px;

    border-bottom: 1px solid var(--color-gray);
  }

  & .delete {
    display: flex;

    width: 100%;
    padding: 12px 0;

    flex-direction: column;
  }
}
