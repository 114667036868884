.card {
  display: flex;

  padding: 10px;

  flex-direction: column;
  align-items: center;

  background: var(--color-light);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;
  border-bottom: 10px solid var(--color-green);

  user-select: none;

  &_unavailable {
    opacity: 0.5;
    cursor: auto;
  }
}

.image {
  display: flex;

  margin-bottom: 17px;

  max-width: 200px;
  width: 100%;

  background: var(--color-light-light-gray);

  & img {
    width: 100%;
  }
}

.info {
  display: flex;

  align-items: center;
  gap: 8px;
}

.type {
  display: flex;

  align-items: center;
  gap: 8px;
}

.icon {
  height: 20px;
  width: 20px;

  color: #2d3c59;
}

.name {
  display: flex;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.14px;
  text-align: center;
  color: var(--color-dark);
}
