@use 'styles/common/mixins' as mixins;

.container {
  display: flex;

  height: 46px;
  width: 300px;

  padding: 6px 16px;

  background: var(--color-light);
  border: 2px solid var(--color-light);

  color: var(--color-dark);

  overflow: hidden;
  @include mixins.transition(ease 0.3s all);

  &:hover:enabled {
    color: var(--color-blue);
  }

  &_active,
  &:active {
    border-color: var(--color-blue);
  }

  &:disabled {
    background: var(--color-light-light-gray);
    border-color: var(--color-light-gray);
    color: var(--color-dark);
  }
}

.form {
  display: flex;
  flex: 1;

  height: 100%;
  width: 100%;

  justify-content: center;
  align-items: center;
}

.input,
.text {
  height: 20px;
  width: 100%;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  background: transparent;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.line {
  position: absolute;
  z-index: 1;
  content: '';

  top: 0;
  right: 0;

  height: 3000px;
  width: 1px;

  border-left: 1px solid var(--color-light-gray);
  opacity: 0.66;
}

.buttonAddInterval {
  height: 24px;
  width: 24px;

  padding: 4px;

  background: var(--color-light-gray);
  border-radius: 50%;

  color: var(--color-light);

  & svg {
    height: 100%;
    width: 100%;
  }
}
