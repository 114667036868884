.option {
  display: flex;

  width: 100%;

  padding: 5px 15px 5px 20px;

  align-items: center;
  gap: 10px;

  background: none;
  color: var(--color-dark);

  &:hover,
  &:focus,
  &:focus-visible,
  &_active {
    background: var(--color-bg-purple);
    color: var(--color-dark);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.icon-wrapper {
  display: flex;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  border-radius: 20px;
  color: var(--color-light);
}

.text {
  display: flex;

  flex-direction: column;
}

.board-name {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: start;
  color: var(--color-dark);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: start;
  color: var(--color-gray);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
