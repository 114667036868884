@use 'styles/common/mixins' as mixins;

.container {
  display: flex;
  position: relative;

  flex-direction: column;

  background: none;

  user-select: none;
}

.header {
  display: flex;

  background: var(--color-light);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;

  align-items: center;

  &:hover {
    .stroke {
      color: var(--color-dark);
    }
  }

  & .name {
    margin-right: 10px;

    max-width: 200px;
    width: 100%;

    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--color-dark);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .avatar {
    display: flex;

    margin-right: 4px;

    min-height: 32px;
    height: 32px;
    min-width: 32px;
    width: 32px;

    justify-content: center;
    align-items: center;

    background: var(--color-bg-light);
    border-radius: 20px;

    color: var(--color-gray);

    & svg {
      height: 20px;
      width: 20px;
    }
  }

  & .stroke {
    display: flex;

    height: 16px;
    width: 16px;

    justify-content: center;
    align-items: center;

    background: none;
    color: var(--color-gray);
  }
}

.tooltip {
  z-index: 5;

  min-width: 100%;
  max-width: 212px;

  padding: 8px 0;

  background: var(--color-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  overflow: hidden;
}

.tooltip__button {
  display: flex;

  flex-grow: 1;
  padding: 12px 20px;

  align-items: center;
  gap: 10px;

  background: none;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-light);
  text-align: start;

  @include mixins.transition(ease 0.3s all);

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  &:hover {
    background: var(--color-gray);
  }
  &_active {
    background: var(--color-gray);
  }
}

.tooltip__title {
  width: 100%;
  padding: 12px 20px;

  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: var(--color-light);
  text-align: start;
}

.profile {
  display: flex;
  flex-grow: 1;

  flex-direction: column;

  border-bottom: 1px solid var(--color-gray);
}

.team {
  display: flex;
  flex-grow: 1;

  flex-direction: column;

  border-bottom: 1px solid var(--color-gray);
}

.logout {
  display: flex;
  flex-grow: 1;

  flex-direction: column;
}
