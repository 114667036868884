.section {
  width: 100%;
}

.container {
  display: flex;
  position: relative;

  min-height: 730px;
  height: 100%;

  max-width: 1220px;
  padding: 0 20px;

  justify-content: space-between;
  gap: 105px;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 800px) {
    padding: 0;

    flex-direction: column;
    align-items: center;
    gap: 36px;
  }
}

.content {
  display: flex;

  max-width: 408px;
  width: 100%;

  padding-top: 151px;

  flex-direction: column;

  @media screen and (max-width: 1024px) {
    max-width: 341px;
  }

  @media screen and (max-width: 800px) {
    max-width: 325px;

    padding: 36px 0 12px;

    align-items: center;
  }
}

.title {
  margin-bottom: 16px;

  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: var(--color-dark);

  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
}

.text {
  margin-bottom: 32px;

  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  color: var(--color-gray);

  @media screen and (max-width: 800px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.button-submit {
  max-width: 322px;
  width: 100%;

  flex: none;
}

.image {
  position: absolute;

  top: 0;
  left: 45%;

  @media screen and (max-width: 800px) {
    position: static;

    max-width: 360px;
    width: 100%;
  }
}
