.small {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.middle {
  font-weight: 300;
  font-size: 28px;
  line-height: 34px;
}

.large {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.03em;
}
