.logo {
  margin-right: auto;

  height: 44px;
  width: 128px;

  & img {
    height: 100%;
    width: 100%;
  }
}

.button-premium {
  margin-right: 24px;
}
